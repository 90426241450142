@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../fonts/roboto/font-roboto.css';

@import "primereact/resources/themes/tailwind-light/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

:root {
  --toastify-color-success: #166534;
  --toastify-icon-color-success: #4ade80;
  --toastify-color-error: #b91c1c;
  --toastify-icon-color-error: #f87171;
}

.Toastify__toast--success {
  @apply bg-green-50 text-sm font-medium text-green-800;
}

.Toastify__toast--error {
  @apply bg-red-50 text-sm font-medium text-red-700;
}
